import React from 'react';
import Link from 'next/link';

const OraminPolicyText = () => {
  return (
    <div>
      <div className="pt-10">
        <h1 className="text-5xl sm:text-6xl m-10 pt-10 text-white">Ora:Min Privacy Policy</h1>
        <p className="m-10 text-white text-sm pt-20">
          This Privacy Policy (“Privacy Policy”) and any terms expressly incorporated herein apply to your access to and/or use of any services made available by Monet Ltd. (“Monet”, “we”, “us”, or “our”) 
          through the Ora:Min website, mobile applications, and/or APIs/Developer Tools, including services related to time tracking and geofencing functionalities (collectively, the “Services”).
          <br/><br/>
          You agree and understand that by accessing or using Monet Services, you affirm that you are at least 18 years old, have the legal capacity to consent to this Privacy Policy, and agree to be bound by its terms in their entirety.
        </p>

        <h2 className="text-xl m-10 pt-10 text-white">Data Collection</h2>
        <p className="m-10 text-white">
          We collect Personal Data when you register for the Services, use our time tracking features, or interact with our geofencing functionalities. “Personal Data” refers to any information that relates to an identified or identifiable individual. This may include, but is not limited to, your name, email address, phone number, location data (for geofencing), and other data necessary for the functioning of our time tracking services.
        </p>

        <h2 className="text-xl m-10 pt-10 text-white">Our Role</h2>
        <p className="m-10 text-white">
          <b>Data Processor:</b> When Monet processes Personal Data on behalf of our business customers (for example, when employees use our time tracking and geofencing features), we act as the data processor. The customer is the data controller, and we process such data based on their instructions.
          <br/><br/>
          <b>Controller:</b> For other Personal Data related to Prospects or Users (excluding Customer Data), Monet acts as the data controller, determining the purposes and means of processing.
        </p>

        <h2 className="text-xl m-10 pt-10 text-white">Geofencing and Location Data</h2>
        <p className="m-10 text-white">
          Monet’s Services may collect and use your location data to enable geofencing features for time tracking purposes. Location data is collected when you interact with Monet’s mobile app while within designated geofenced areas established by your employer. This data is used strictly for monitoring work hours and attendance, in line with the terms of service.
        </p>

        <h2 className="text-xl m-10 pt-10 text-white">Security and Data Protection</h2>
        <p className="m-10 text-white">
          We implement industry-standard security measures to protect your Personal Data. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.
        </p>

        <h2 className="text-xl m-10 pt-10 text-white">Contact Us</h2>
        <p className="m-10 text-white">
          If you have any questions or concerns about this Privacy Policy or your Personal Data, please email us at <a href="mailto:julianomonet@gmail.com" className="text-blue-400">info@monet.io</a>.
        </p>

        <h2 className="text-xl m-10 pt-10 text-white">Changes to This Policy</h2>
        <p className="m-10 text-white">
          We may update this Privacy Policy from time to time to reflect changes in our practices. If we make material changes, we will notify you by email or through the Services.
        </p>
      </div>
    </div>
  );
}

export default OraminPolicyText;