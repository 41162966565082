


import React from 'react';
import Link from 'next/link';

const OraMinPrivacyPolicy = () => <Link prefetch={false} className="text-blue-400" href="/legal/oramin_policy">Privacy Policy</Link>;

const OraminTermsText = () => {
  return (
    <div>
      <div className="pt-10">
        <h1 className="text-4xl md:text-8xl lg:text-9xl sm:text-4xl m-10 pt-10 text-white">Ora:MIn Terms & Conditions</h1>
        <p className="m-10 pt-10 text-white text-xs">These Terms of Use were last updated on January 6, 2025.</p>

        <h2 className="text-xl m-10 pt-10 text-white">Introduction</h2>
        <div className="m-10 text-white">
          Welcome to our platform. By accessing or using our services, you agree to comply with these terms and conditions along with our <OraMinPrivacyPolicy />.
          If you do not agree, please stop using our services.
        </div>

        <h2 className="text-xl m-10 pt-10 text-white">Eligibility</h2>
        <div className="m-10 text-white">
          You must be at least 18 years of age to use our platform. By using our services, you confirm that you are legally capable and authorized to enter into this agreement.
        </div>

        <h2 className="text-xl m-10 pt-10 text-white">Changes to Terms</h2>
        <div className="m-10 text-white">
          We reserve the right to modify these terms at any time. Changes will be posted here and take effect immediately. Your continued use of the platform after changes indicates your acceptance of the new terms.
        </div>

        <h2 className="text-xl m-10 pt-10 text-white">Account Registration</h2>
        <div className="m-10 text-white">
          To use certain features of the platform, you may be required to create an account and provide personal information. You are responsible for maintaining the confidentiality of your account details and agree to notify us of any unauthorized use.
        </div>

        <h2 className="text-xl m-10 pt-10 text-white">Privacy & Data Protection</h2>
        <div className="m-10 text-white">
          We take your privacy seriously. Our <OraMinPrivacyPolicy /> explains how we collect, use, and protect your information.
        </div>

        <h2 className="text-xl m-10 pt-10 text-white">Use of Services</h2>
        <div className="m-10 text-white">
          You agree to use the services only for lawful purposes and in accordance with these terms. You are solely responsible for any activity under your account.
        </div>

        <h2 className="text-xl m-10 pt-10 text-white">Data Deletion</h2>
        <div className="m-10 text-white">
          If you wish to delete your account and all associated data, you can do so directly from within the app. To delete your account, follow these steps:
          <ol className="list-decimal ml-5">
            <li>Go to your Profile by tapping on your avatar or name.</li>
            <li>Select <strong>Settings</strong> from the menu.</li>
            <li>Scroll down to find the <strong>Delete Account</strong> option.</li>
            <li>Confirm your choice, and your account, along with all personal data, will be permanently deleted.</li>
          </ol>
          Please note that once deleted, your data cannot be recovered.
        </div>
        
        <h2 className="text-xl m-10 pt-10 text-white">Termination</h2>
        <div className="m-10 text-white">
          We may terminate or suspend your access to the platform if you violate these terms or engage in any unlawful activity. Termination will not affect any accrued rights or obligations.
        </div>

        <h2 className="text-xl m-10 pt-10 text-white">Governing Law</h2>
        <div className="m-10 text-white">
          These terms will be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
        </div>

        <h2 className="text-xl m-10 pt-10 text-white">Contact Information</h2>
        <div className="m-10 text-white">
          If you have any questions about these terms, please contact us at info@monet.io
        </div>
      </div>
    </div>
  );
};

export default OraminTermsText;
